body {
  margin: 0;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.previous,
.next {
  display: flex !important;
  align-items: center !important;
  width: 22px;
  cursor: pointer;
  height: 22px;
  text-align: center;
  border: 1px solid #e57c29;
  border-radius: 50px;
}

.previous > a > svg {
  fill: #e57c29 !important;
}
.next > a > svg {
  fill: #e57c29 !important;
}
.page-link {
  width: 22px;
  cursor: pointer;
  height: 22px;
  border: 1px solid #e57c29;
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-link:hover {
  background: #e57c29;
}
.page-link > a {
  color: #e57c29 !important;
}
.page-link > a:hover {
  color: white !important;
}
.active-page-link,
.active-page-link > a {
  background: #e57c29;
  color: white !important;
}
